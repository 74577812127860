<script>
	import googlePlayBadge from '$lib/assets/google-play-badge.png';
	import appleBadgeBlack from '$lib/assets/apple-badge-black.svg';
	import appleBadgeWhite from '$lib/assets/apple-badge-white.svg';

	export let isApple = true;
	const link = isApple
		? 'https://apps.apple.com/us/app/client-note-tracker/id6475484569?platform=iphone'
		: 'https://play.google.com/store/apps/details?id=com.clientnotepadmobilefrontend';
</script>

<a href={link} rel="noopener noreferrer" target="_blank">
	{#if isApple}
		<img src={appleBadgeWhite} class="padded" alt="Download on the App Store" />
	{:else}
		<img src={googlePlayBadge} alt="Get it on Google Play" />
	{/if}
</a>

<style>
	a {
		/* border: 1px solid var(--primary); */
		padding: 0px;
		margin: 0px;
	}
	img.padded {
		padding: 12px;
	}
	img {
		height: 80px;
		box-sizing: border-box;
		margin: 0px;
	}
</style>
